.accordion {
    .card {
        border: 0;
        .card-header {
            border: 0;
            background: transparent !important;
            a {
                &.collapsed {
                    .arrow {
                        transition: 0.3s;
                        transform: rotate(180deg);
                    }
                }
                .arrow {
                    transition: 0.3s;
                    transform: rotate(0deg);
                }
            }
        }
    }
}
// faqs 2
.section-faqs-2 {
    .ellipse-center {
        width: 579.311px;
        height: 680.767px;
        transform: rotate(90.766deg);
        border-radius: 680.767px;
        opacity: 0.1;
        background: var(--Theme-Primary, #6d4df2);
        filter: blur(100px);
    }
}
