.change-price-plan {
    height: 41px;
}
.monthly,
.yearly {
    &.active {
        background: var(--tc-linear-2) !important;
        color: #fff !important;
        transition: 0.3s;
    }
}
a:hover {
    color: dark !important;
    text-decoration: none;
}
.rotate-center {
    -webkit-animation: rotate-center 15s linear infinite;
    animation: rotate-center 15s linear infinite;
}
.rotate-center-rev {
    -webkit-animation: rotate-center 5s linear infinite reverse;
    animation: rotate-center 5s linear infinite reverse;
}
/* ----------------------------------------------
 * Generated by Animista on 2024-6-22 17:22:7
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */
/**
 * ----------------------------------------
 * animation rotate-center
 * ----------------------------------------
 */
@-webkit-keyframes rotate-center {
    0% {
        -webkit-transform: rotate(0);
        transform: rotate(0);
    }
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}
@keyframes rotate-center {
    0% {
        -webkit-transform: rotate(0);
        transform: rotate(0);
    }
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}
.section-pricing-1 {
    .ellipse-rotate-success {
        width: 603.435px;
        height: 842.878px;
        transform: rotate(90.766deg);
        border-radius: 842.878px;
        opacity: 0.15;
        background: #64ade1;
        filter: blur(100px);
        top: 8%;
        left: 15%;
    }
    .ellipse-rotate-primary {
        width: 579.311px;
        height: 680.767px;
        transform: rotate(90.766deg);
        border-radius: 680.767px;
        opacity: 0.1;
        background: var(--Theme-Primary, #6d4df2);
        filter: blur(100px);
        top: 15%;
        right: 15%;
    }
    .card-team {
        &:hover {
            svg {
                transition: 0.3s;
                path {
                    transition: 0.3s;
                    fill: var(--tc-theme-primary);
                }
            }
        }
    }
}
