.mobile-header-wrapper-style {
    position: fixed;
    top: 0;
    max-width: 380px;
    width: 100%;
    min-height: 100vh;
    bottom: 0;
    right: 0;
    visibility: hidden;
    opacity: 0;
    -webkit-transition: all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1);
    -o-transition: all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1);
    transition: all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1);
    background-color: var(--tc-neutral-0);
    -webkit-box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.09);
    box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.09);
    z-index: 1006;
    &.sidebar-visible, &.sidebar-canvas-visible {
        visibility: visible;
        opacity: 1;
        -webkit-transform: translate(0, 0);
        -ms-transform: translate(0, 0);
        transform: translate(0, 0);
    }
    .mobile-header-wrapper-inner {
        padding: 0px 0px 30px;
        height: 100%;
        overflow-y: auto;
        overflow-x: hidden;
        .burger-icon {
            top: 25px;
            right: 30px;
            position: absolute;
            height: 34px;
            width: 34px;
        }
        .mobile-header-logo {
            padding: 20px 24px;
            border-bottom: 1px solid var(--tc-neutral-200);
            position: relative;
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 25px;
            .burger-icon {
                display: inline-block;
                position: relative;
                height: 40px;
                width: 40px;
                background-color: var(--tc-theme-primary-soft);
                background-position: center;
                background-repeat: no-repeat;
                top: auto;
                right: auto;
            }
        }
        .mobile-header-top {
            display: -webkit-box;
            display: -webkit-flex;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-pack: justify;
            -webkit-justify-content: space-between;
            -ms-flex-pack: justify;
            justify-content: space-between;
            -webkit-flex-wrap: wrap;
            -ms-flex-wrap: wrap;
            flex-wrap: wrap;
            padding: 15px 10px 13px 10px;
            background-color: var(--tc-neutral-0);
            -webkit-box-align: center;
            -webkit-align-items: center;
            -ms-flex-align: center;
            align-items: center;
            align-self: center;
            .box-author-profile {
                margin-bottom: 10px;
            }
            .mobile-header-logo {
                a {
                    display: block;
                    img {
                        width: 100px;
                    }
                }
            }
        }
        .mobile-header-content-area {
            padding: 0px 30px 0px 30px;
            .mobile-menu-wrap {
                nav {
                    .mobile-menu {
                        li {
                            display: block;
                            position: relative;
                            padding: 0px 0;
                            margin-bottom: 20px;
                            &.hr {
                                display: none;
                            }
                            &:last-child {
                                border-bottom: none;
                            }
                            &.has-children {
                                .menu-expand {
                                    right: 0;
                                    position: absolute;
                                    cursor: pointer;
                                    z-index: 9;
                                    text-align: center;
                                    font-size: 12px;
                                    display: block;
                                    width: 30px;
                                    height: 30px;
                                    line-height: 46px;
                                    top: 0px;
                                    color: var(--tc-neutral-900);
                                    i {
                                        height: 24px;
                                        width: 24px;
                                        display: inline-block;
                                        opacity: 1;
                                        background: url(../imgs/template/icons/arrow-right-big.svg) no-repeat center;
                                    }
                                }
                                &.active {
                                    & > .menu-expand {
                                        background: rgba(255, 255, 255, 0.2);
                                        i {
                                            background: url(../imgs/template/icons/arrow-down-big.svg) no-repeat center;
                                        }
                                    }
                                }
                            }
                            a {
                                font-size: 16px;
                                line-height: 26px;
                                text-transform: capitalize;
                                font-weight: 700;
                                position: relative;
                                display: inline-block;
                                color: var(--tc-neutral-900);
                                transition-duration: 0.2s;
                                i {
                                    margin-right: 5px;
                                }
                            }
                            ul {
                                padding: 10px 0 0 0px;
                                li {
                                    padding: 0px 0;
                                    margin-bottom: 12px;
                                    border-bottom: none;
                                    &.has-children {
                                        .menu-expand {
                                            top: 0px;
                                        }
                                    }
                                    &:hover {
                                        a {
                                            padding-left: 13px;
                                            transition-duration: 0.2s;
                                            &::before {
                                                opacity: 1;
                                                transition-duration: 0.2s;
                                            }
                                        }
                                    }
                                    a {
                                        font-size: 15px;
                                        line-height: 22px;
                                        display: block;
                                        font-weight: 500;
                                        color: var(--tc-neutral-500);
                                        padding-left: 0px;
                                        position: relative;
                                        transition-duration: 0.2s;
                                        padding-left: 8px;
                                    }
                                    ul {
                                        margin-top: 0;
                                    }
                                    &.has-children.active {
                                        padding-bottom: 0;
                                    }
                                }
                            }
                            &:hover {
                                & > a {
                                    color: var(--tc-theme-primary);
                                    padding-left: 3px;
                                    transition-duration: 0.2s;
                                }
                            }
                        }
                    }
                }
            }
            .categories-dropdown-wrap ul li a {
                padding: 5px 15px;
            }
            .mobile-header-info-wrap {
                padding: 20px;
                border-radius: 5px;
                border: 1px solid var(--tc-neutral-200);
                margin: 17px 0 30px 0;
                .single-mobile-header-info {
                    position: relative;
                    margin-bottom: 13px;
                    &:last-child {
                        margin-bottom: 0;
                    }
                    a {
                        font-size: 14px;
                        display: block;
                        font-weight: 500;
                        color: var(--tc-neutral-900);
                        &:hover {
                            color: var(--tc-theme-primary);
                        }
                        i {
                            font-size: 14px;
                            color: var(--tc-theme-primary);
                            margin-right: 8px;
                        }
                    }
                    .lang-curr-dropdown {
                        margin-top: 5px;
                        display: none;
                        background-color: transparent;
                        -webkit-box-shadow: none;
                        box-shadow: none;
                        padding: 10px 0 0 0;
                        width: 100%;
                        z-index: 11;
                        ul {
                            li {
                                padding-bottom: 10px;
                                &:last-child {
                                    padding-bottom: 0px;
                                }
                                a {
                                    font-size: 16px;
                                    color: rgba(255, 255, 255, 0.7);
                                    font-weight: 400;
                                    &:hover {
                                        color: var(--tc-theme-primary);
                                    }
                                }
                            }
                        }
                    }
                    &:hover {
                        & > a {
                            color: var(--tc-neutral-900);
                        }
                    }
                }
            }
            .mobile-header-border {
                border-bottom: 1px solid rgba(255, 255, 255, 0.15);
            }
            .site-copyright {
                font-size: 14px;
                color: var(--tc-neutral-800);
            }
        }
    }
}

.burger-icon {
    position: absolute;
    width: 40px;
    height: 40px;
    cursor: pointer;
    -webkit-transition: all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1);
    transition: all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1);
    top: 0px;
    right: 0px;
    z-index: 999999;
    display: none;
    background-color: var(--tc-theme-primary-soft);
    & > span {
        display: block;
        position: absolute;
        left: 10px;
        width: 20px;
        height: 1px;
        &.burger-icon-top {
            top: 13px;
        }
        &.burger-icon-bottom {
            bottom: 12px;
        }
        &.burger-icon-mid {
            top: 19px;
        }
    }
    img {
        display: inline-block;
        vertical-align: middle;
    }
    &.burger-close {
        & > span {
            &.burger-icon-top {
                display: none;
                opacity: 0;
            }
            &.burger-icon-mid {
                top: 18px;
                transform: rotate(45deg);
            }
            &.burger-icon-bottom {
                bottom: 19px;
                transform: rotate(-45deg);
            }
        }
    }
}
.burger-icon > span::before,
.burger-icon > span::after {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    content: "";
    background-color: var(--tc-theme-primary-dark);
}

@media (max-width: 992px) {
    .burger-icon {
        display: block;
        top: 24px;
        right: 24px;  
    }
    .header-4 {
        .burger-icon {
            display: block;
            top: 16px;
            right: 24px;  
        }
    }
}
@media (max-width: 768px) {
    .burger-icon {
        display: block;
        top: 21px;
        right: 24px;  
    }
}