.card-service {
    transition: 0.3s ease-in-out;
    &:hover {
        border-color: var(--tc-theme-primary-light) !important;
        transition: 0.3s ease-in-out;
        .icon-learn-more {
            width: auto;
            height: 35px;
            padding-left: 17px;
            padding-right: 17px;
            .arrow,
            span {
                display: inline;
                visibility: visible;
            }
            .plus {
                display: none;
                visibility: hidden;
            }
        }
        .icon-flip {
            .icon {
                transform: rotateY(180deg);
            }
        }
    }
    .icon-learn-more {
        width: 35px;
        height: 35px;
        .arrow,
        span {
            visibility: hidden;
            display: none;
        }
    }
}
