.section-services-4 {
    .bouncing-blobs-glass {
        background: rgba(238, 242, 255, 0.1);
        backdrop-filter: blur(100px);
        -webkit-backdrop-filter: blur(100px);
    }
    .nav-pills .nav-link.active,
    .nav-pills .show > .nav-link {
        color: var(--tc-system-white);
        background-color: var(--tc-theme-primary);
    }
    .nav-link {
        display: block;
        padding: 19px 0px 19px 43px;
        font-size: 20px;
        background-color: var(--tc-system-white);
        margin-bottom: 8px;
        color: var(--tc-neutral-900);
    }
}
