[data-bs-theme='dark'] {
    //theme
    --tc-theme-primary: var(--tc-theme-dark-primary);
    --tc-theme-primary-light: var(--tc-theme-dark-primary-light);
    --tc-theme-secondary: var(--tc-theme-dark-secondary);
    --tc-theme-primary-soft: var(--tc-theme-dark-primary-soft);
    --tc-theme-primary-dark: var(--tc-theme-dark-primary-dark);

    //neutral    
    --tc-neutral-0: var(--tc-neutral-dark-0);
    --tc-neutral-100: var(--tc-neutral-dark-100);
    --tc-neutral-200: var(--tc-neutral-dark-200);
    --tc-neutral-300: var(--tc-neutral-dark-300);
    --tc-neutral-400: var(--tc-neutral-dark-400);
    --tc-neutral-500: var(--tc-neutral-dark-500);
    --tc-neutral-600: var(--tc-neutral-dark-600);
    --tc-neutral-700: var(--tc-neutral-dark-700);
    --tc-neutral-800: var(--tc-neutral-dark-800);
    --tc-neutral-900: var(--tc-neutral-dark-900);

    //system
    --tc-system-success: var(--tc-system-dark-success);
    --tc-system-info: var(--tc-system-dark-info);
    --tc-system-warning: var(--tc-system-dark-warning);
    --tc-system-danger: var(--tc-system-dark-danger);
    --tc-system-white: var(--tc-system-dark-white);
    --tc-system-dark: var(--tc-system-dark-dark);
    --tc-system-muted: var(--tc-system-dark-muted);

    //border
    --tc-border-1: var(--tc-border-dark-1);
    --tc-border-2: var(--tc-border-dark-2);

    //bg
    --tc-bg-1: var(--tc-bg-dark-1);
    --tc-bg-2: var(--tc-bg-dark-2);
    --tc-bg-3: var(--tc-bg-dark-3);
    --tc-bg-4: var(--tc-bg-dark-4);
    --tc-bg-5: var(--tc-bg-dark-5);
    --tc-bg-6: var(--tc-bg-dark-6);
    --tc-bg-7: var(--tc-bg-dark-7);

    //gradient
    --tc-linear-1: var(--tc-linear-dark-1);
    --tc-linear-3: var(--tc-linear-dark-3);
    --tc-linear-4: var(--tc-linear-dark-4);

    //shadow
    --tc-shadow-1: var(--tc-shadow-dark-1);
    --tc-shadow-2: var(--tc-shadow-dark-2);
    
    //CUSTOM CLASS
    body {
        background: var(--tc-system-dark-white);
    }
    .backdrop-filter {
        background: rgba(17, 17, 17, 0.5);
        box-shadow:
            1.313px 1.969px 0px 0px rgba(70, 69, 69, 0.6) inset,
            -12.177px 36.69px 44.901px 0px rgba(127, 124, 168, 0.18);
        backdrop-filter: blur(8.028975486755371px);
    }
    .filter-invert {
        filter: invert(100%);
    }
    .mask-image-2 {
        height: 0;
    }
    .section-newsletter-3 {
        .bg-4 {
            background-color: var(--tc-neutral-200) !important;
        }
    }
}
