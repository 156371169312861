.section-blog-2 {
    .bouncing-blobs-glass {
        background: rgba(243, 244, 246, 0.1);
        backdrop-filter: blur(100px);
        -webkit-backdrop-filter: blur(100px);
    }
    .bouncing-blob--green {
        height: 419.259px;
        width: 842.878px;
        border-radius: 842.878px;
        opacity: 0.15;
        background: #64e1b0;
    }
    .bouncing-blob--primary {
        height: 419.259px;
        width: 842.878px;
        border-radius: 842.878px;
        opacity: 0.15;
        background: var(--tc-theme-primary);
    }
    .bg-linear-primary {
        background: linear-gradient(88deg, #6d4df2 1.17%, rgba(140, 113, 255, 0) 99.12%);
        opacity: 0.8;
    }
    .dashed-line-1 {
        position: absolute;
        top: 75px;
        left: 30px;
        width: 1px;
        height: 125px;
        border: 1px dashed var(--tc-system-white);
    }
    .dashed-line-2 {
        position: absolute;
        top: 280px;
        left: 30px;
        width: 1px;
        height: 125px;
        border: 1px dashed var(--tc-system-white);
    }
}
