.section-hero-2 {
    .bouncing-blobs-glass {
        background: rgba(163, 140, 255, 0.1);
    }
}
.socials {
    .icon {
        svg {
            height: 14px;
            width: 14px;
            fill: var(--tc-neutral-500);
        }
        &:hover {
            svg {
                fill: var(--tc-neutral-900);
                transition-duration: 0.3s;
            }
        }
    }
}
