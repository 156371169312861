.section-hero-3 {
    .ellipse-primary {
        width: 398.868px;
        height: 735.298px;
        transform: rotate(94.975deg);
        border-radius: 735.298px;
        opacity: 0.28;
        background: var(--tc-theme-primary);
        filter: blur(100px);
    }
}
