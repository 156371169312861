.section-cta-1 {
    .bouncing-blob {
        width: 32vw;
        aspect-ratio: 1;
        border-radius: 50%;
        will-change: transform;
        position: absolute;
        z-index: 1;
        top: 0;
        left: 0;
        transform-origin: left top;
    }
    .bouncing-blob--blue {
        background: #64ade1;
        opacity: 0.15;
        width: 603.435px;
        height: 842.878px;
    }
    .bouncing-blob--primary {
        background: rgba($color: #6d4df2, $alpha: 0.1);
        height: 579.311px;
        width: 680.767px;
    }
    .bouncing-blobs-container {
        position: absolute;
        z-index: -1;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
    .bouncing-blobs-glass {
        position: absolute;
        z-index: 2;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba($color: #fff, $alpha: 0.1);
        backdrop-filter: blur(100px);
        -webkit-backdrop-filter: blur(100px);
        pointer-events: none;
    }
    .bouncing-blobs {
        position: absolute;
        z-index: 1;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
    @media (max-width: 1200px) {
        .bouncing-blobs-glass {
            backdrop-filter: blur(100px);
            -webkit-backdrop-filter: blur(100px);
        }
    }
    @media (max-width: 500px) {
        .bouncing-blob {
            width: 60vw;
        }
        .bouncing-blob--white {
            width: 30vw;
        }
        .bouncing-blobs-glass {
            backdrop-filter: blur(90px);
            -webkit-backdrop-filter: blur(90px);
        }
    }
}
