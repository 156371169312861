.section-feature-5 {
    .photo-description {
        .box-gradient-1 {
            width: 509px;
            height: 446px;
            transform: rotate(-2.178deg);
            transition: 0.3s ease-in-out;
        }
        &:hover {
            .box-gradient-1 {
                transform: rotate(-5deg);
                transition: 0.3s ease-in-out;
            }
            .box-gradient-2 {
                transform: rotate(5deg);
                transition: 0.3s ease-in-out;
            }
        }
        .box-gradient-2 {
            width: 560px;
            height: 452px;
            transform: rotate(2.034deg);
            transition: 0.3s ease-in-out;
        }
    }
}
.img-decorate-2 {
    width: 422.972px;
    height: 842.878px;
    transform: rotate(90.766deg);
    border-radius: 842.878px;
    opacity: 0.15;
    background: var(--System-White, #fff);
    filter: blur(100px);
}
