.section-newsletter-1 {
    .ellipse-rotate-1 {
        width: 331.416px;
        height: 389.457px;
        border-radius: 389.457px;
        opacity: 0.1;
        background: var(--tc-theme-primary);
        filter: blur(100px);
        top: -0;
        left: 0;
    }
    .ellipse-rotate-2 {
        width: 287.502px;
        height: 384.651px;
        border-radius: 384.651px;
        opacity: 0.2;
        background: var(--tc-theme-primary);
        filter: blur(100px);
        top: 0;
        right: 0;
    }
    .rotate-center {
        -webkit-animation: rotate-center 15s linear infinite;
        animation: rotate-center 15s linear infinite;
    }
    .rotate-center-rev {
        -webkit-animation: rotate-center 5s linear infinite reverse;
        animation: rotate-center 5s linear infinite reverse;
    }
    /* ----------------------------------------------
     * Generated by Animista on 2024-6-22 17:22:7
     * Licensed under FreeBSD License.
     * See http://animista.net/license for more info. 
     * w: http://animista.net, t: @cssanimista
     * ---------------------------------------------- */
    /**
     * ----------------------------------------
     * animation rotate-center
     * ----------------------------------------
     */
    @-webkit-keyframes rotate-center {
        0% {
            -webkit-transform: rotate(0);
            transform: rotate(0);
        }
        100% {
            -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
        }
    }
    @keyframes rotate-center {
        0% {
            -webkit-transform: rotate(0);
            transform: rotate(0);
        }
        100% {
            -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
        }
    }
    .card-team {
        &:hover {
            svg {
                transition: 0.3s;
                path {
                    transition: 0.3s;
                    fill: var(--tc-theme-primary);
                }
            }
        }
    }
    input {
        &::placeholder {
            color: var(--tc-neutral-500);
            font-weight: 500;
        }
    }
    .input-group {
        .icon-input {
            background: var(--tc-system-white) !important;
            min-height: 60px !important;
            display: flex;
            align-items: center;
        }
        .form-control {
            background: var(--tc-neutral-100) !important;
            min-height: 60px !important;
            color: var(--tc-neutral-900) !important;
            &::placeholder {
                color: var(--tc-neutral-500) !important;
                opacity: 0.7 !important;
            }
        }
    }
}
