.project-item {
    .card-team {
        opacity: 0;
    }
    &:hover {
        .card-team {
            opacity: 1 !important;
        }
    }
}
