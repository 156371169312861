.howitwork-2 {
    .bouncing-blobs-glass {
        background: rgba(242, 251, 249, 0.1);
        backdrop-filter: blur(100px);
        -webkit-backdrop-filter: blur(100px);
    }
    .navigation-arrow-1 {
        left: 35%;
    }
    .navigation-arrow-2 {
        right: 35%;
        bottom: 45%;
    }
}
