.features-1 {
    .star-lg {
        bottom: -5%;
        left: 22%;
    }
    .star-md {
        top: -12px;
        right: 45%;
    }
}
.icon-flip {
    &::after {
        content: '';
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        background: var(--tc-theme-primary);
        border-radius: 3px;
        bottom: 0;
        width: 24px;
        height: 2px;
        background-color: var();
    }
    .icon {
        transition: all 0.3s ease-in-out;
    }
    &:hover {
        .icon {
            transform: rotateY(180deg);
        }
    }
}
.feature-item {
    &:hover {
        .icon-flip {
            .icon {
                transform: rotateY(180deg);
            }
        }
    }
}
