.phase-items {
    .phase-item {
        transition: 0.3s;
        &:hover {
            padding-left: 0.7rem;
            transition: 0.3s;
        }
    }
}
.bg-rotate {
    bottom: -15%;
    left: -10%;
}
.rotate-center {
    -webkit-animation: rotate-center 15s linear infinite both;
    animation: rotate-center 15s linear infinite both;
}
/* ----------------------------------------------
 * Generated by Animista on 2024-6-21 15:45:34
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */
/**
 * ----------------------------------------
 * animation rotate-center
 * ----------------------------------------
 */
@-webkit-keyframes rotate-center {
    0% {
        -webkit-transform: rotate(0);
        transform: rotate(0);
    }
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}
@keyframes rotate-center {
    0% {
        -webkit-transform: rotate(0);
        transform: rotate(0);
    }
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}
// bloomn
/**
  * Bloom
  *
  * @author jh3y - jheytompkins.com
*/
.bloom {
    height: 80px;
    position: relative;
    width: 80px;
}
.bloom:before,
.bloom:after {
    border-radius: 100%;
    content: '';
    display: block;
    height: 80px;
    width: 80px;
}
.bloom:before {
    background: linear-gradient(22.5deg, #ffffff 0%, #794aff 100%);
}
.bloom:after {
    -webkit-animation: scale 1.5s infinite;
    animation: scale 1.5s infinite;
    border: 1px solid #e2e2e2;
    left: 0;
    position: absolute;
    top: 0;
}
@-webkit-keyframes scale {
    0%,
    50% {
        opacity: 0;
    }
    55% {
        opacity: 0.5;
    }
    100% {
        opacity: 0;
        -webkit-transform: scale(2);
        transform: scale(2);
    }
}
@keyframes scale {
    0%,
    50% {
        opacity: 0;
    }
    55% {
        opacity: 0.5;
    }
    100% {
        opacity: 0;
        -webkit-transform: scale(2);
        transform: scale(2);
    }
}
