.section-team-1 {
    .ellipse-rotate-success {
        width: 419.259px;
        height: 842.878px;
        border-radius: 842.878px;
        opacity: 0.15;
        background: var(--tc-system-success);
        filter: blur(100px);
        top: -20%;
        left: 10%;
    }
    .ellipse-rotate-primary {
        width: 419.259px;
        height: 842.878px;
        border-radius: 842.878px;
        opacity: 0.15;
        background: var(--tc-theme-primary);
        filter: blur(100px);
        top: -15%;
        right: 10%;
    }
    .rotate-center {
        -webkit-animation: rotate-center 15s linear infinite;
        animation: rotate-center 15s linear infinite;
    }
    .rotate-center-rev {
        -webkit-animation: rotate-center 5s linear infinite reverse;
        animation: rotate-center 5s linear infinite reverse;
    }
    /* ----------------------------------------------
 * Generated by Animista on 2024-6-22 17:22:7
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */
    /**
 * ----------------------------------------
 * animation rotate-center
 * ----------------------------------------
 */
    @-webkit-keyframes rotate-center {
        0% {
            -webkit-transform: rotate(0);
            transform: rotate(0);
        }
        100% {
            -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
        }
    }
    @keyframes rotate-center {
        0% {
            -webkit-transform: rotate(0);
            transform: rotate(0);
        }
        100% {
            -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
        }
    }
    .card-team {
        &:hover {
            svg {
                transition: 0.3s;
                path {
                    transition: 0.3s;
                    fill: var(--tc-theme-primary) !important;
                }
            }
        }
    }
}
