.avt-hero {
    margin-left: -10%;
}
.shape-1 {
    bottom: -148px;
    right: 35px;
}
.shape-2 {
    top: 40px;
    right: 35px;
}
.shape-3 {
    top: 15px;
    left: 80px;
}
.card-hero {
    bottom: -52px;
    left: 90px;
}
