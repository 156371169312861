// .social-icons {
//     a {
//         transition: 0.3s;
//         &:hover {
//             svg {
//                 transition: 0.3s;
//                 path {
//                     transition: 0.3s;
//                     fill: var(--tc-theme-primary);
//                 }
//             }
//         }
//     }
// }
.main-logo {
    span {
        font-size: 24px;
        font-weight: bold;
        color: var(--tc-system-dark);
    }
}
