// Navbar
.navbar {
    padding: 0.5rem;
    background-color: transparent;
    backdrop-filter: blur(50px);
    @media (max-width: 992px) {
        padding: 15px 0;
    }
    @media (max-width: 570px) {
        padding: 20px 0;
        &.header-4 {
            padding: 16px 0;
        }
    }
    .navbar-brand {
        padding-top: 0.2rem;
        padding-bottom: 0.2rem;
        &.main-logo {
            span {
                font-size: 24px;
                font-weight: bold;
                color: var(--tc-system-dark);
            }
        }
    }
    .navbar-toggler {
        border: 2px solid var(--tc-neutral-900);
        padding: 0.25rem 0.5rem;
        font-size: 1.5rem;
        color: var(--tc-neutral-900);
        &:focus {
            outline: 0rem;
            box-shadow: none;
        }
    }
    .dropdown {
        .dropdown-toggle {
            &:after {
                color: var(--tc-neutral-900);
                font-family: 'bootstrap-icons' !important;
                content: '\F282';
                line-height: 1.5rem;
                margin-left: 0.625rem;
            }
            &:after:hover {
                transform: rotate(180deg);
            }
        }
    }
    .dropdown-menu {
        top: 100%;
        color: var(--tc-neutral-900);
        border: 1px solid var(--tc-neutral-200);
        min-width: 12rem;
        box-shadow: var(--tc-shadow-1);
        border-radius: 0.5rem;
        margin-top: 0rem;
        @media (max-width: 992px) {
            box-shadow: none;
            min-width: 0 !important;
        }
        .dropdown-submenu {
            a {
                &::after {
                    transform: rotate(-90deg);
                    position: absolute;
                    right: 1rem;
                }
            }
        }
        .dropdown-submenu.dropend {
            .dropdown-menu {
                top: -18px;
                right: auto;
                left: 100%;
                box-shadow: var(--tc-shadow-1);
                min-width: 12rem;
                border-radius: 0.5rem;
                @media (max-width: 992px) {
                    box-shadow: none;
                }
            }
        }
    }
    .dropdown-fullwidth {
        position: static;
        .dropdown-menu {
            left: 0 !important;
            margin-left: auto;
            margin-right: auto;
            width: 100%;
            max-width: 1140px;
            right: 0 !important;
            top: 100%;
            transform: none !important;
        }
    }
    .navbar-nav {
        .dropdown {
            @media (min-width: 990px) {
                > .dropdown-menu {
                    left: 0;
                    transform: translate(0%, 18px);
                }
            }
        }
        .dropend {
            > .dropdown-menu {
                transform: translateY(10px);
            }
        }
        .nav-item {
            &.menu-item-has-children {
                position: relative;
                .nav-link {
                    &::after {
                        content: '';
                        position: absolute;
                        top: 15px;
                        right: -4px;
                        height: 16px;
                        width: 16px;
                        margin-left: 4px;
                        background-image: url('../imgs/template/icons/arrow-down.svg');
                    }
                    &::before {
                        content: '';
                        position: absolute;
                        top: 0;
                        right: -15px;
                        height: 80px;
                        width: 100px;
                    }
                }
            }
            .nav-link {
                font-size: 1rem;
                color: var(--tc-neutral-900);
                font-weight: 500;
                padding: 0.5rem 1rem;
                border-radius: 0.25rem;
                @media (max-width: 992px) {
                    padding: 0.75rem 0rem !important;
                    border-bottom: 1px solid var(--tc-neutral-200);
                    border-radius: 0rem;
                    &:hover {
                        color: var(--tc-neutral-900);
                        background-color: transparent !important;
                    }
                }
                &:focus-visible {
                    box-shadow: none;
                }
                &:hover {
                    color: var(--tc-theme-primary);
                }
                &.active {
                    color: var(--tc-theme-primary);
                }
            }
        }
    }
    .has-mega-menu {
        position: static !important;
        .nav-link {
            position: relative;
            &::after {
                content: '';
                position: absolute;
                top: 50%;
                right: -2px;
                height: 16px;
                width: 16px;
                margin-top: -6px;
                background-image: url('../imgs/template/icons/arrow-down.svg');
                transition: all 0.9s ease-in-out;
            }
            &::before {
                content: '';
                position: absolute;
                top: 0;
                right: -20px;
                height: 80px;
                width: 105px;
            }
        }
        .mega-menu {
            display: none;
            position: absolute;
            top: 100%;
            padding: 2rem;
            min-width: 1296px;
            border: 1px solid var(--tc-neutral-200);
            border-radius: 0.5rem;
            box-shadow: var(--tc-shadow-1);
            background-color: var(--tc-system-white);
            transition: all 0.9s ease-in-out;
            z-index: 999;
        }
        &:hover {
            .mega-menu {
                display: block;
                transition: all 0.9s ease-in-out;
            }
        }
    }
    .has-mega-menu-2 {
        position: static !important;
        .nav-link {
            position: relative;
            &::after {
                content: '';
                position: absolute;
                top: 50%;
                right: -2px;
                height: 16px;
                width: 16px;
                margin-top: -6px;
                background-image: url('../imgs/template/icons/arrow-down.svg');
                transition: all 0.9s ease-in-out;
            }
            &::before {
                content: '';
                position: absolute;
                top: 0;
                right: -20px;
                height: 80px;
                width: 104px;
            }
        }
        .mega-menu {
            display: none;
            position: absolute;
            top: 100%;
            left: 680px;
            padding: 2rem;
            width: 856px;
            height: 334px;
            border: 1px solid var(--tc-neutral-200);
            border-radius: 0.5rem;
            box-shadow: var(--tc-shadow-1);
            background-color: var(--tc-system-white);
            transition: all 0.9s ease-in-out;
        }
        &:hover {
            .mega-menu {
                display: block;
                transition: all 0.9s ease-in-out;
            }
        }
    }

    @media (min-width: 992px) {
        &.header-4 {
            padding: 20px 0;
        }
    }
}
// Responsive breakpoints
@media (min-width: 1200px) {
    .navbar {
        .dropdown-menu.show {
            display: none;
        }
        .dropdown-menu {
            display: block;
            visibility: hidden;
            opacity: 0;
            -webkit-transition: all 0.3s ease-in-out;
            -o-transition: all 0.3s ease-in-out;
            transition: all 0.3s ease-in-out;
            transform: translate(-50%);
        }
        .dropdown {
            &:hover {
                > .dropdown-menu {
                    opacity: 1;
                    visibility: visible;
                }
            }
        }
        .dropdown-submenu {
            &:hover {
                > .dropdown-menu {
                    transform: translateY(10px);
                    opacity: 1;
                    visibility: visible;
                }
            }
        }
    }
}
// navbar dark
.navbar-dark {
    transition: all 0.3s ease-in-out;
    .navbar-brand {
        -webkit-filter: brightness(0) invert(1);
        filter: brightness(0) invert(1);
    }
    .navbar-nav {
        .nav-link {
            color: var(--tc-neutral-300) !important;
            @media (max-width: 990px) {
                color: var(--tc-neutral-900) !important;
            }
        }
        .dropdown {
            .dropdown-toggle {
                &:after {
                    color: var(--tc-neutral-300);
                    @media (max-width: 990px) {
                        color: var(--tc-neutral-900) !important;
                    }
                }
            }
        }
    }
}
// navbar light
.navbar-light {
    .navbar-nav {
        .nav-item {
            .nav-link {
                color: var(--tc-neutral-900);
                font-weight: 500;
                &:hover,
                &.active {
                    color: var(--tc-neutral-900) !important;
                    background-color: transparent !important;
                    // svg {
                    //     path {
                    //         fill: var(--tc-theme-primary);
                    //     }
                    // }
                }
            }
        }
    }
}
// navbar transparent
.navbar-transparent {
    background-color: transparent;
    position: absolute !important;
    width: 100%;
    z-index: 1009;
    left: 0;
    right: 0;
    box-shadow: none;
    .navbar-toggler {
        border-color: var(--tc-neutral-0);
        color: var(--tc-neutral-0);
    }
}
@media (max-width: 990px) {
    .navbar-transparent.navbar-clone {
        .navbar-brand {
            filter: brightness(0) invert(1);
        }
        .navbar-toggler {
            border-color: var(--tc-neutral-900);
            color: var(--tc-neutral-900);
        }
    }
    .navbar-transparent.navbar-stick {
        .navbar-brand {
            filter: brightness(1) invert(0);
        }
    }
}
// navbar clone
.navbar-clone {
    position: fixed !important;
    z-index: 1008;
    top: 0;
    left: 0;
    -webkit-transform: translateY(-100%);
    -ms-transform: translateY(-100%);
    transform: translateY(-100%);
    -webkit-transition: all 300ms ease-in-out;
    -moz-transition: all 300ms ease-in-out;
    transition: all 300ms ease-in-out;
    box-shadow: none;
}
// navbar stick
.navbar-stick {
    -webkit-transform: translateY(0%);
    -ms-transform: translateY(0%);
    transform: translateY(0%);
    background-color: rgb(255, 255, 255);
    background-color: rgba(255, 255, 255, 0.5);
    backdrop-filter: blur(50px);
    border-bottom: 1px solid rgba(255, 255, 255, 0.3);
    &:not(.navbar-dark) {
        box-shadow: var(--tc-shadow-1);
        // background: var(--tc-system-white);
        &.navbar-brand {
            -webkit-filter: brightness(0) invert(1);
            filter: brightness(0) invert(1);
        }
    }
}
// offcanvas
.offcanvas.show:not(.hiding),
.offcanvas.showing {
    transform: none;
}
.offcanvas {
    height: 100vh;
}
.z-999 {
    z-index: 999 !important;
}
.dark-light-switcher {
    cursor: pointer;
}
